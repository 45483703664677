<template>
  <div>
    <b-row align-v="center" :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <title-page title="Informativo" class="titulo"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        {{ mensagem }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TitlePage from '../components/TitlePage.vue'
export default {
  name: 'sobre',
  components:{
      TitlePage
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  data(){
    return {
      mensagem: 'Aguarde ...',
      loadingRemove: false,
      Toast: this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
    async Remove(){
      if(!this.loadingRemove){
        this.loadingRemove = true;
        this.axios
        .post("https://andlima.com.br/hl/api?acao=news-remove", this.form)
        .then((response) => {
          this.loadingRemove = false;
          if(response.data.acao === 'ERRO'){
            this.ToastError(response.data.mensagem)
          } else {
            this.MessageSuccess()
            this.ClearFields();
          }
        })
        .catch((error) => {
          this.loadingRemove = false;
          this.ToastError('Erro ao enviar tente novamente mais tarde.')
        });
      }
    },
    ToastError(msg) {
      this.Toast.fire({
        icon: 'error',
        title: msg,
      })
    },
    MessageSuccess() {
      this.$swal.fire({
        icon: 'success',
        title: 'Confirmação',
        text: 'E-mail cadastrado com sucesso!',
      })
    }
  },
  created(){
    Remove();
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#0D245E"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_CONTATO.jpg')" : "url('/img/BG_CONTATO.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_CONTATO.jpg')" : "url('/img/sm/BG_CONTATO.webp')"
  },
}
</script>
<style lang="scss" scoped>
.texto{
  padding: 10px;
  text-align: justify !important;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 18px;
}
.titulo{
  margin-bottom: 60px !important;
}
a{
  display: block !important;
  background-color: #0a7bac;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 18px;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
  width: 100%;
  text-align: center !important;
  &:hover{
    background-color: #0098DA;
  }
}
</style>